window.phones = [
  {
    currency: "RUB",
    alpha2: "RU",
    alpha3: "RUS",
    phone_prefix: 7,
    phone_sample: "912 345 67 89",
    id: 3159,
  },
  {
    currency: "AZN",
    alpha2: "AZ",
    alpha3: "AZE",
    phone_prefix: 994,
    phone_sample: "40 123 45 67",
    id: 81,
  },
  {
    currency: "UZS",
    alpha2: "UZ",
    alpha3: "UZB",
    phone_prefix: 998,
    phone_sample: "91 234 56 78",
    id: 9787,
  },
  {
    currency: "UAH",
    alpha2: "UA",
    alpha3: "UKR",
    phone_prefix: 380,
    phone_sample: "50 123 4567",
    id: 9908,
  },
  {
    currency: "KZT",
    alpha2: "KZ",
    alpha3: "KAZ",
    phone_prefix: 7,
    phone_sample: "771 000 9998",
    id: 10129,
  },
  {
    currency: "BYN",
    alpha2: "BY",
    alpha3: "BLR",
    phone_prefix: 375,
    phone_sample: "29 491 19 11",
    id: 248,
  },
  {
    currency: "USD",
    alpha2: "AU",
    alpha3: "AUS",
    phone_prefix: 61,
    phone_sample: "123 456789",
    id: 9933,
  },
  {
    currency: "EUR",
    alpha2: "AT",
    alpha3: "AUT",
    phone_prefix: 43,
    phone_sample: "1234567891",
    id: 9935,
  },
  {
    currency: "USD",
    alpha2: "SQ",
    alpha3: "ALB",
    phone_prefix: 355,
    phone_sample: "692 123 456",
    id: 9913,
  },
  {
    currency: "USD",
    alpha2: "DZ",
    alpha3: "DZA",
    phone_prefix: 213,
    phone_sample: "551 23 45 67",
    id: 9915,
  },
  {
    currency: "USD",
    alpha2: "AO",
    alpha3: "AGO",
    phone_prefix: 244,
    phone_sample: "923 123 456",
    id: 9921,
  },
  {
    currency: "ARS",
    alpha2: "AR",
    alpha3: "ARG",
    phone_prefix: 54,
    phone_sample: "9 11 2345 678",
    id: 9929,
  },
  {
    currency: "AMD",
    alpha2: "AM",
    alpha3: "ARM",
    phone_prefix: 374,
    phone_sample: "77 123456",
    id: 245,
  },
  {
    currency: "USD",
    alpha2: "AF",
    alpha3: "AFG",
    phone_prefix: 93,
    phone_sample: "70 123 4567",
    id: 9909,
  },
  {
    currency: "USD",
    alpha2: "BS",
    alpha3: "BHS",
    phone_prefix: 1242,
    phone_sample: "359 1234",
    id: 9937,
  },
  {
    currency: "BDT",
    alpha2: "BD",
    alpha3: "BGD",
    phone_prefix: 880,
    phone_sample: "1812 345678",
    id: 9941,
  },
  {
    currency: "USD",
    alpha2: "BB",
    alpha3: "BRB",
    phone_prefix: 1246,
    phone_sample: "250 1234",
    id: 9943,
  },
  {
    currency: "USD",
    alpha2: "BH",
    alpha3: "BHR",
    phone_prefix: 973,
    phone_sample: "3600 1234",
    id: 9939,
  },
  {
    currency: "USD",
    alpha2: "BZ",
    alpha3: "BLZ",
    phone_prefix: 501,
    phone_sample: "622 1234",
    id: 9947,
  },
  {
    currency: "EUR",
    alpha2: "BE",
    alpha3: "BEL",
    phone_prefix: 32,
    phone_sample: "123 456 789",
    id: 9945,
  },
  {
    currency: "USD",
    alpha2: "BJ",
    alpha3: "BEN",
    phone_prefix: 229,
    phone_sample: "90 01 12 34",
    id: 9949,
  },
  {
    currency: "EUR",
    alpha2: "BG",
    alpha3: "BGR",
    phone_prefix: 359,
    phone_sample: "48 123 4567",
    id: 9971,
  },
  {
    currency: "USD",
    alpha2: "BO",
    alpha3: "BOL",
    phone_prefix: 591,
    phone_sample: "71234567",
    id: 9955,
  },
  {
    currency: "USD",
    alpha2: "BA",
    alpha3: "BIH",
    phone_prefix: 387,
    phone_sample: "61 123 456",
    id: 9959,
  },
  {
    currency: "USD",
    alpha2: "BW",
    alpha3: "BWA",
    phone_prefix: 267,
    phone_sample: "71 123 456",
    id: 9961,
  },
  {
    currency: "BRL",
    alpha2: "BR",
    alpha3: "BRA",
    phone_prefix: 55,
    phone_sample: "11 96123 4567",
    id: 9965,
  },
  {
    currency: "USD",
    alpha2: "BN",
    alpha3: "BRN",
    phone_prefix: 673,
    phone_sample: "712 3456",
    id: 9969,
  },
  {
    currency: "USD",
    alpha2: "BF",
    alpha3: "BFA",
    phone_prefix: 226,
    phone_sample: "70 12 34 56",
    id: 9973,
  },
  {
    currency: "USD",
    alpha2: "BI",
    alpha3: "BDI",
    phone_prefix: 257,
    phone_sample: "79 56 12 34",
    id: 9975,
  },
  {
    currency: "USD",
    alpha2: "BT",
    alpha3: "BTN",
    phone_prefix: 975,
    phone_sample: "17 12 34 56",
    id: 9953,
  },
  {
    currency: "USD",
    alpha2: "GB",
    alpha3: "GBR",
    phone_prefix: 44,
    phone_sample: "7400 123456",
    id: 10357,
  },
  {
    currency: "EUR",
    alpha2: "HU",
    alpha3: "HUN",
    phone_prefix: 36,
    phone_sample: "20 123 4567",
    id: 10101,
  },
  {
    currency: "USD",
    alpha2: "VE",
    alpha3: "VEN",
    phone_prefix: 58,
    phone_sample: "412 1234567",
    id: 10367,
  },
  {
    currency: "USD",
    alpha2: "VI",
    alpha3: "VIR",
    phone_prefix: 1340,
    phone_sample: "123 45 67",
    id: 10373,
  },
  {
    currency: "USD",
    alpha2: "VN",
    alpha3: "VNM",
    phone_prefix: 84,
    phone_sample: "91 234 56 78",
    id: 10369,
  },
  {
    currency: "USD",
    alpha2: "GA",
    alpha3: "GAB",
    phone_prefix: 241,
    phone_sample: "076 031 23",
    id: 10061,
  },
  {
    currency: "USD",
    alpha2: "HT",
    alpha3: "HTI",
    phone_prefix: 509,
    phone_sample: "34 10 1234",
    id: 10091,
  },
  {
    currency: "USD",
    alpha2: "GY",
    alpha3: "GUY",
    phone_prefix: 592,
    phone_sample: "609 1234",
    id: 10089,
  },
  {
    currency: "USD",
    alpha2: "GM",
    alpha3: "GMB",
    phone_prefix: 220,
    phone_sample: "301 2345",
    id: 10063,
  },
  {
    currency: "USD",
    alpha2: "GH",
    alpha3: "GHA",
    phone_prefix: 233,
    phone_sample: "23 123 4567",
    id: 10067,
  },
  {
    currency: "USD",
    alpha2: "GP",
    alpha3: "GLP",
    phone_prefix: 590,
    phone_sample: "690 00 12 34",
    id: 10077,
  },
  {
    currency: "USD",
    alpha2: "GT",
    alpha3: "GTM",
    phone_prefix: 502,
    phone_sample: "5123 4567",
    id: 10081,
  },
  {
    currency: "USD",
    alpha2: "GF",
    alpha3: "GUF",
    phone_prefix: 594,
    phone_sample: "694 20 12 34",
    id: 10055,
  },
  {
    currency: "USD",
    alpha2: "GN",
    alpha3: "GIN",
    phone_prefix: 224,
    phone_sample: "601 12 34 56",
    id: 10085,
  },
  {
    currency: "USD",
    alpha2: "GW",
    alpha3: "GNB",
    phone_prefix: 245,
    phone_sample: "955 012 345",
    id: 10087,
  },
  {
    currency: "EUR",
    alpha2: "DE",
    alpha3: "DEU",
    phone_prefix: 49,
    phone_sample: "1512 34567890",
    id: 10065,
  },
  {
    currency: "USD",
    alpha2: "HN",
    alpha3: "HND",
    phone_prefix: 504,
    phone_sample: "9123 4567",
    id: 10097,
  },
  {
    currency: "USD",
    alpha2: "HK",
    alpha3: "HKG",
    phone_prefix: 852,
    phone_sample: "5123 4567",
    id: 10099,
  },
  {
    currency: "EUR",
    alpha2: "GR",
    alpha3: "GRC",
    phone_prefix: 30,
    phone_sample: "691 234 5678",
    id: 10071,
  },
  {
    currency: "USD",
    alpha2: "GE",
    alpha3: "GEO",
    phone_prefix: 995,
    phone_sample: "555 12 34 56",
    id: 1280,
  },
  {
    currency: "EUR",
    alpha2: "DK",
    alpha3: "DNK",
    phone_prefix: 45,
    phone_sample: "31 12 34 56",
    id: 10023,
  },
  {
    currency: "USD",
    alpha2: "DJ",
    alpha3: "DJI",
    phone_prefix: 253,
    phone_sample: "77 83 10 01",
    id: 10025,
  },
  {
    currency: "USD",
    alpha2: "DO",
    alpha3: "DOM",
    phone_prefix: 1809,
    phone_sample: "234 5678",
    id: 10029,
  },
  {
    currency: "USD",
    alpha2: "EG",
    alpha3: "EGY",
    phone_prefix: 20,
    phone_sample: "100 123 4567",
    id: 10033,
  },
  {
    currency: "USD",
    alpha2: "ZM",
    alpha3: "ZMB",
    phone_prefix: 260,
    phone_sample: "95 5123456",
    id: 10381,
  },
  {
    currency: "USD",
    alpha2: "EH",
    alpha3: "ESH",
    phone_prefix: 212,
    phone_sample: "650 123456",
    id: 10377,
  },
  {
    currency: "USD",
    alpha2: "ZW",
    alpha3: "ZWE",
    phone_prefix: 263,
    phone_sample: "71 234 5678",
    id: 10383,
  },
  {
    currency: "USD",
    alpha2: "IL",
    alpha3: "ISR",
    phone_prefix: 972,
    phone_sample: "1 234 567 89",
    id: 10117,
  },
  {
    currency: "INR",
    alpha2: "IN",
    alpha3: "IND",
    phone_prefix: 91,
    phone_sample: "8123456789",
    id: 10105,
  },
  {
    currency: "USD",
    alpha2: "ID",
    alpha3: "IDN",
    phone_prefix: 62,
    phone_sample: "1234567",
    id: 10107,
  },
  {
    currency: "USD",
    alpha2: "JO",
    alpha3: "JOR",
    phone_prefix: 962,
    phone_sample: "7 9012 3456",
    id: 10127,
  },
  {
    currency: "USD",
    alpha2: "IQ",
    alpha3: "IRQ",
    phone_prefix: 964,
    phone_sample: "791 234 5678",
    id: 10111,
  },
  {
    currency: "IRR",
    alpha2: "IR",
    alpha3: "IRN",
    phone_prefix: 98,
    phone_sample: "9123 45 6789",
    id: 10109,
  },
  {
    currency: "EUR",
    alpha2: "IE",
    alpha3: "IRL",
    phone_prefix: 353,
    phone_sample: "85 012 3456",
    id: 10113,
  },
  {
    currency: "EUR",
    alpha2: "IS",
    alpha3: "ISL",
    phone_prefix: 354,
    phone_sample: "611 1234",
    id: 10103,
  },
  {
    currency: "EUR",
    alpha2: "ES",
    alpha3: "ESP",
    phone_prefix: 34,
    phone_sample: "931 234567",
    id: 10311,
  },
  {
    currency: "EUR",
    alpha2: "IT",
    alpha3: "ITA",
    phone_prefix: 39,
    phone_sample: "3 123 45 67 89",
    id: 10119,
  },
  {
    currency: "EUR",
    alpha2: "YE",
    alpha3: "YEM",
    phone_prefix: 967,
    phone_sample: "712 345 678",
    id: 10379,
  },
  {
    currency: "USD",
    alpha2: "CV",
    alpha3: "CPV",
    phone_prefix: 238,
    phone_sample: "991 12 34",
    id: 9977,
  },
  {
    currency: "USD",
    alpha2: "KH",
    alpha3: "KHM",
    phone_prefix: 855,
    phone_sample: "91 234 567",
    id: 9979,
  },
  {
    currency: "USD",
    alpha2: "CM",
    alpha3: "CMR",
    phone_prefix: 237,
    phone_sample: "6 71 23 45 67",
    id: 9981,
  },
  {
    currency: "USD",
    alpha2: "CA",
    alpha3: "CAN",
    phone_prefix: 1,
    phone_sample: "506 234 5678",
    id: 9983,
  },
  {
    currency: "USD",
    alpha2: "QA",
    alpha3: "QAT",
    phone_prefix: 974,
    phone_sample: "3312 3456",
    id: 10255,
  },
  {
    currency: "USD",
    alpha2: "KE",
    alpha3: "KEN",
    phone_prefix: 254,
    phone_sample: "712 123456",
    id: 10131,
  },
  {
    currency: "EUR",
    alpha2: "CY",
    alpha3: "CYP",
    phone_prefix: 357,
    phone_sample: "123 45 67",
    id: 10019,
  },
  {
    currency: "USD",
    alpha2: "CN",
    alpha3: "CHN",
    phone_prefix: 86,
    phone_sample: "131 2345 6789",
    id: 9993,
  },
  {
    currency: "USD",
    alpha2: "CO",
    alpha3: "COL",
    phone_prefix: 57,
    phone_sample: "321 1234567",
    id: 9999,
  },
  {
    currency: "USD",
    alpha2: "CD",
    alpha3: "COD",
    phone_prefix: 243,
    phone_sample: "991 234 567",
    id: 10005,
  },
  {
    currency: "USD",
    alpha2: "CR",
    alpha3: "CRI",
    phone_prefix: 506,
    phone_sample: "8312 3456",
    id: 10009,
  },
  {
    currency: "USD",
    alpha2: "CI",
    alpha3: "CIV",
    phone_prefix: 225,
    phone_sample: "01 23 45 67",
    id: 10011,
  },
  {
    currency: "USD",
    alpha2: "CU",
    alpha3: "CUB",
    phone_prefix: 53,
    phone_sample: "5 1234567",
    id: 10015,
  },
  {
    currency: "USD",
    alpha2: "KW",
    alpha3: "KWT",
    phone_prefix: 965,
    phone_sample: "500 12345",
    id: 10139,
  },
  {
    currency: "KGS",
    alpha2: "KG",
    alpha3: "KGZ",
    phone_prefix: 996,
    phone_sample: "700 123 456",
    id: 2303,
  },
  {
    currency: "USD",
    alpha2: "LA",
    alpha3: "LAO",
    phone_prefix: 856,
    phone_sample: "12345678",
    id: 10141,
  },
  {
    currency: "EUR",
    alpha2: "LV",
    alpha3: "LVA",
    phone_prefix: 371,
    phone_sample: "21 234 567",
    id: 10143,
  },
  {
    currency: "USD",
    alpha2: "LS",
    alpha3: "LSO",
    phone_prefix: 266,
    phone_sample: "5012 3456",
    id: 10147,
  },
  {
    currency: "USD",
    alpha2: "LR",
    alpha3: "LBR",
    phone_prefix: 231,
    phone_sample: "77 012 3456",
    id: 10149,
  },
  {
    currency: "USD",
    alpha2: "LB",
    alpha3: "LBN",
    phone_prefix: 961,
    phone_sample: "71 123 456",
    id: 10145,
  },
  {
    currency: "USD",
    alpha2: "LY",
    alpha3: "LBY",
    phone_prefix: 218,
    phone_sample: "91 2345678",
    id: 10151,
  },
  {
    currency: "EUR",
    alpha2: "LT",
    alpha3: "LTU",
    phone_prefix: 370,
    phone_sample: "612 34567",
    id: 10155,
  },
  {
    currency: "EUR",
    alpha2: "LU",
    alpha3: "LUX",
    phone_prefix: 352,
    phone_sample: "628 123 456",
    id: 10157,
  },
  {
    currency: "USD",
    alpha2: "MU",
    alpha3: "MUS",
    phone_prefix: 230,
    phone_sample: "5251 2345",
    id: 10181,
  },
  {
    currency: "USD",
    alpha2: "MR",
    alpha3: "MRT",
    phone_prefix: 222,
    phone_sample: "22 12 34 56",
    id: 10179,
  },
  {
    currency: "USD",
    alpha2: "MG",
    alpha3: "MDG",
    phone_prefix: 261,
    phone_sample: "32 12 345 67",
    id: 10163,
  },
  {
    currency: "USD",
    alpha2: "YT",
    alpha3: "MYT",
    phone_prefix: 262,
    phone_sample: "12 3456",
    id: 10183,
  },
  {
    currency: "USD",
    alpha2: "MO",
    alpha3: "MAC",
    phone_prefix: 853,
    phone_sample: "6612 3456",
    id: 10159,
  },
  {
    currency: "USD",
    alpha2: "MK",
    alpha3: "MKD",
    phone_prefix: 389,
    phone_sample: "72 345 678",
    id: 10161,
  },
  {
    currency: "USD",
    alpha2: "MW",
    alpha3: "MWI",
    phone_prefix: 265,
    phone_sample: "991 23 45 67",
    id: 10165,
  },
  {
    currency: "USD",
    alpha2: "MY",
    alpha3: "MYS",
    phone_prefix: 60,
    phone_sample: "12 345 6789",
    id: 10167,
  },
  {
    currency: "USD",
    alpha2: "ML",
    alpha3: "MLI",
    phone_prefix: 223,
    phone_sample: "65 01 23 45",
    id: 10171,
  },
  {
    currency: "USD",
    alpha2: "MV",
    alpha3: "MDV",
    phone_prefix: 960,
    phone_sample: "771 2345",
    id: 10169,
  },
  {
    currency: "USD",
    alpha2: "MA",
    alpha3: "MAR",
    phone_prefix: 212,
    phone_sample: "650 123456",
    id: 10197,
  },
  {
    currency: "USD",
    alpha2: "MQ",
    alpha3: "MTQ",
    phone_prefix: 596,
    phone_sample: "696 20 12 34",
    id: 10177,
  },
  {
    currency: "USD",
    alpha2: "MX",
    alpha3: "MEX",
    phone_prefix: 52,
    phone_sample: "222 123 4567",
    id: 10185,
  },
  {
    currency: "USD",
    alpha2: "MZ",
    alpha3: "MOZ",
    phone_prefix: 258,
    phone_sample: "82 123 4567",
    id: 10199,
  },
  {
    currency: "MDL",
    alpha2: "MD",
    alpha3: "MDA",
    phone_prefix: 373,
    phone_sample: "621 12 345",
    id: 2788,
  },
  {
    currency: "RUB",
    alpha2: "MN",
    alpha3: "MNG",
    phone_prefix: 976,
    phone_sample: "8812 3456",
    id: 10191,
  },
  {
    currency: "USD",
    alpha2: "MM",
    alpha3: "MMR",
    phone_prefix: 95,
    phone_sample: "12 345 6789",
    id: 10201,
  },
  {
    currency: "USD",
    alpha2: "NA",
    alpha3: "NAM",
    phone_prefix: 264,
    phone_sample: "81 123 4567",
    id: 10203,
  },
  {
    currency: "USD",
    alpha2: "NP",
    alpha3: "NPL",
    phone_prefix: 977,
    phone_sample: "984 1234567",
    id: 10207,
  },
  {
    currency: "USD",
    alpha2: "NE",
    alpha3: "NER",
    phone_prefix: 227,
    phone_sample: "93 12 34 56",
    id: 10217,
  },
  {
    currency: "USD",
    alpha2: "NG",
    alpha3: "NGA",
    phone_prefix: 234,
    phone_sample: "802 123 4567",
    id: 10219,
  },
  {
    currency: "EUR",
    alpha2: "NL",
    alpha3: "NLD",
    phone_prefix: 31,
    phone_sample: "12 345 6789",
    id: 10209,
  },
  {
    currency: "USD",
    alpha2: "NI",
    alpha3: "NIC",
    phone_prefix: 505,
    phone_sample: "8123 4567",
    id: 10215,
  },
  {
    currency: "USD",
    alpha2: "NZ",
    alpha3: "NZL",
    phone_prefix: 64,
    phone_sample: "21 123 4567",
    id: 10213,
  },
  {
    currency: "USD",
    alpha2: "NC",
    alpha3: "NCL",
    phone_prefix: 687,
    phone_sample: "123 456",
    id: 10211,
  },
  {
    currency: "NOK",
    alpha2: "NO",
    alpha3: "NOR",
    phone_prefix: 47,
    phone_sample: "406 12 345",
    id: 10227,
  },
  {
    currency: "USD",
    alpha2: "AE",
    alpha3: "ARE",
    phone_prefix: 971,
    phone_sample: "50 123 4567",
    id: 10355,
  },
  {
    currency: "USD",
    alpha2: "OM",
    alpha3: "OMN",
    phone_prefix: 968,
    phone_sample: "9212 3456",
    id: 10229,
  },
  {
    currency: "USD",
    alpha2: "PK",
    alpha3: "PAK",
    phone_prefix: 92,
    phone_sample: "301 2345678",
    id: 10231,
  },
  {
    currency: "USD",
    alpha2: "PS",
    alpha3: "PSE",
    phone_prefix: 970,
    phone_sample: "599 123 456",
    id: 10235,
  },
  {
    currency: "USD",
    alpha2: "PA",
    alpha3: "PAN",
    phone_prefix: 507,
    phone_sample: "6123 4567",
    id: 10237,
  },
  {
    currency: "USD",
    alpha2: "PG",
    alpha3: "PNG",
    phone_prefix: 675,
    phone_sample: "7012 3456",
    id: 10239,
  },
  {
    currency: "USD",
    alpha2: "PY",
    alpha3: "PRY",
    phone_prefix: 595,
    phone_sample: "961 456789",
    id: 10241,
  },
  {
    currency: "USD",
    alpha2: "PE",
    alpha3: "PER",
    phone_prefix: 51,
    phone_sample: "912 345 678",
    id: 10243,
  },
  {
    currency: "PLN",
    alpha2: "PL",
    alpha3: "POL",
    phone_prefix: 48,
    phone_sample: "123 456 789",
    id: 10249,
  },
  {
    currency: "EUR",
    alpha2: "PT",
    alpha3: "PRT",
    phone_prefix: 351,
    phone_sample: "912 345 678",
    id: 10251,
  },
  {
    currency: "USD",
    alpha2: "PR",
    alpha3: "PRI",
    phone_prefix: 1787,
    phone_sample: "234 5678",
    id: 10253,
  },
  {
    currency: "USD",
    alpha2: "CG",
    alpha3: "COG",
    phone_prefix: 242,
    phone_sample: "06 123 4567",
    id: 10003,
  },
  {
    currency: "USD",
    alpha2: "RE",
    alpha3: "REU",
    phone_prefix: 262,
    phone_sample: "692 12 34 56",
    id: 10257,
  },
  {
    currency: "USD",
    alpha2: "RW",
    alpha3: "RWA",
    phone_prefix: 250,
    phone_sample: "720 123 456",
    id: 10261,
  },
  {
    currency: "USD",
    alpha2: "RO",
    alpha3: "ROU",
    phone_prefix: 40,
    phone_sample: "712 034 567",
    id: 10259,
  },
  {
    currency: "USD",
    alpha2: "US",
    alpha3: "USA",
    phone_prefix: 1,
    phone_sample: "321 123 45 67",
    id: 10359,
  },
  {
    currency: "USD",
    alpha2: "SV",
    alpha3: "SLV",
    phone_prefix: 503,
    phone_sample: "7012 3456",
    id: 10035,
  },
  {
    currency: "USD",
    alpha2: "ST",
    alpha3: "STP",
    phone_prefix: 239,
    phone_sample: "981 2345",
    id: 10281,
  },
  {
    currency: "USD",
    alpha2: "SA",
    alpha3: "SAU",
    phone_prefix: 966,
    phone_sample: "51 234 5678",
    id: 10283,
  },
  {
    currency: "USD",
    alpha2: "SZ",
    alpha3: "SWZ",
    phone_prefix: 268,
    phone_sample: "7612 3456",
    id: 10321,
  },
  {
    currency: "USD",
    alpha2: "KP",
    alpha3: "PRK",
    phone_prefix: 850,
    phone_sample: "12 345678",
    id: 10135,
  },
  {
    currency: "USD",
    alpha2: "SN",
    alpha3: "SEN",
    phone_prefix: 221,
    phone_sample: "70 123 45 67",
    id: 10285,
  },
  {
    currency: "USD",
    alpha2: "RS",
    alpha3: "SRB",
    phone_prefix: 381,
    phone_sample: "60 1234567",
    id: 10287,
  },
  {
    currency: "USD",
    alpha2: "SG",
    alpha3: "SGP",
    phone_prefix: 65,
    phone_sample: "8123 4567",
    id: 10293,
  },
  {
    currency: "USD",
    alpha2: "SY",
    alpha3: "SYR",
    phone_prefix: 963,
    phone_sample: "944 567 890",
    id: 10327,
  },
  {
    currency: "EUR",
    alpha2: "SK",
    alpha3: "SVK",
    phone_prefix: 421,
    phone_sample: "912 123 456",
    id: 10297,
  },
  {
    currency: "EUR",
    alpha2: "SI",
    alpha3: "SVN",
    phone_prefix: 386,
    phone_sample: "31 234 567",
    id: 10299,
  },
  {
    currency: "USD",
    alpha2: "SB",
    alpha3: "SLB",
    phone_prefix: 677,
    phone_sample: "1234567",
    id: 10301,
  },
  {
    currency: "USD",
    alpha2: "SO",
    alpha3: "SOM",
    phone_prefix: 252,
    phone_sample: "7 11234567",
    id: 10303,
  },
  {
    currency: "USD",
    alpha2: "SD",
    alpha3: "SDN",
    phone_prefix: 249,
    phone_sample: "91 123 1234",
    id: 10315,
  },
  {
    currency: "USD",
    alpha2: "SR",
    alpha3: "SUR",
    phone_prefix: 597,
    phone_sample: "741 2345",
    id: 10317,
  },
  {
    currency: "USD",
    alpha2: "SL",
    alpha3: "SLE",
    phone_prefix: 232,
    phone_sample: "25 123456",
    id: 10291,
  },
  {
    currency: "USD",
    alpha2: "TJ",
    alpha3: "TJK",
    phone_prefix: 992,
    phone_sample: "917 12 3456",
    id: 9575,
  },
  {
    currency: "USD",
    alpha2: "TH",
    alpha3: "THA",
    phone_prefix: 66,
    phone_sample: "81 234 5678",
    id: 10333,
  },
  {
    currency: "USD",
    alpha2: "TW",
    alpha3: "TWN",
    phone_prefix: 886,
    phone_sample: "912 345 678",
    id: 10329,
  },
  {
    currency: "USD",
    alpha2: "SW",
    alpha3: "TZA",
    phone_prefix: 255,
    phone_sample: "712 345 678",
    id: 10331,
  },
  {
    currency: "USD",
    alpha2: "TL",
    alpha3: "TLS",
    phone_prefix: 670,
    phone_sample: "1234567",
    id: 10335,
  },
  {
    currency: "USD",
    alpha2: "TG",
    alpha3: "TGO",
    phone_prefix: 228,
    phone_sample: "90 11 23 45",
    id: 10337,
  },
  {
    currency: "USD",
    alpha2: "TT",
    alpha3: "TTO",
    phone_prefix: 1868,
    phone_sample: "291 1234",
    id: 10343,
  },
  {
    currency: "USD",
    alpha2: "TN",
    alpha3: "TUN",
    phone_prefix: 216,
    phone_sample: "20 123 456",
    id: 10345,
  },
  {
    currency: "RUB",
    alpha2: "TM",
    alpha3: "TKM",
    phone_prefix: 993,
    phone_sample: "66 123456",
    id: 9638,
  },
  {
    currency: "TRY",
    alpha2: "TR",
    alpha3: "TUR",
    phone_prefix: 90,
    phone_sample: "501 234 56 78",
    id: 10347,
  },
  {
    currency: "USD",
    alpha2: "UG",
    alpha3: "UGA",
    phone_prefix: 256,
    phone_sample: "712 345678",
    id: 10353,
  },
  {
    currency: "USD",
    alpha2: "UY",
    alpha3: "URY",
    phone_prefix: 598,
    phone_sample: "94 231 234",
    id: 10363,
  },
  {
    currency: "USD",
    alpha2: "FJ",
    alpha3: "FJI",
    phone_prefix: 679,
    phone_sample: "701 2345",
    id: 10049,
  },
  {
    currency: "USD",
    alpha2: "PH",
    alpha3: "PHL",
    phone_prefix: 63,
    phone_sample: "905 123 4567",
    id: 10245,
  },
  {
    currency: "EUR",
    alpha2: "FI",
    alpha3: "FIN",
    phone_prefix: 358,
    phone_sample: "41 2345678",
    id: 10051,
  },
  {
    currency: "EUR",
    alpha2: "FR",
    alpha3: "FRA",
    phone_prefix: 33,
    phone_sample: "123 4567",
    id: 10053,
  },
  {
    currency: "USD",
    alpha2: "HR",
    alpha3: "HRV",
    phone_prefix: 385,
    phone_sample: "92 123 4567",
    id: 10013,
  },
  {
    currency: "USD",
    alpha2: "CF",
    alpha3: "CAF",
    phone_prefix: 236,
    phone_sample: "70 01 23 45",
    id: 9987,
  },
  {
    currency: "USD",
    alpha2: "TD",
    alpha3: "TCD",
    phone_prefix: 235,
    phone_sample: "63 01 23 45",
    id: 9989,
  },
  {
    currency: "RUB",
    alpha2: "ME",
    alpha3: "MNE",
    phone_prefix: 382,
    phone_sample: "67 622 901",
    id: 10193,
  },
  {
    currency: "CZK",
    alpha2: "CZ",
    alpha3: "CZE",
    phone_prefix: 420,
    phone_sample: "123 456 789",
    id: 10021,
  },
  {
    currency: "USD",
    alpha2: "CL",
    alpha3: "CHL",
    phone_prefix: 56,
    phone_sample: "2 2123 4567",
    id: 9991,
  },
  {
    currency: "EUR",
    alpha2: "CH",
    alpha3: "CHE",
    phone_prefix: 41,
    phone_sample: "78 123 45 67",
    id: 10325,
  },
  {
    currency: "EUR",
    alpha2: "SE",
    alpha3: "SWE",
    phone_prefix: 46,
    phone_sample: "70 123 45 67",
    id: 10323,
  },
  {
    currency: "USD",
    alpha2: "LK",
    alpha3: "LKA",
    phone_prefix: 94,
    phone_sample: "71 234 5678",
    id: 10313,
  },
  {
    currency: "USD",
    alpha2: "EC",
    alpha3: "ECU",
    phone_prefix: 593,
    phone_sample: "99 123 4567",
    id: 10031,
  },
  {
    currency: "USD",
    alpha2: "GQ",
    alpha3: "GNQ",
    phone_prefix: 240,
    phone_sample: "222 123 456",
    id: 10037,
  },
  {
    currency: "USD",
    alpha2: "ER",
    alpha3: "ERI",
    phone_prefix: 291,
    phone_sample: "7 123 456",
    id: 10039,
  },
  {
    currency: "EUR",
    alpha2: "EE",
    alpha3: "EST",
    phone_prefix: 372,
    phone_sample: "5123 4567",
    id: 10041,
  },
  {
    currency: "USD",
    alpha2: "ET",
    alpha3: "ETH",
    phone_prefix: 251,
    phone_sample: "91 123 4567",
    id: 10043,
  },
  {
    currency: "USD",
    alpha2: "ZA",
    alpha3: "ZAF",
    phone_prefix: 27,
    phone_sample: "71 123 4567",
    id: 10305,
  },
  {
    currency: "USD",
    alpha2: "KR",
    alpha3: "KOR",
    phone_prefix: 82,
    phone_sample: "10 2000 000",
    id: 10137,
  },
  {
    currency: "USD",
    alpha2: "SS",
    alpha3: "SSD",
    phone_prefix: 211,
    phone_sample: "977 123 456",
    id: 10309,
  },
  {
    currency: "USD",
    alpha2: "JM",
    alpha3: "JAM",
    phone_prefix: 1876,
    phone_sample: "210 1234",
    id: 10121,
  },
  {
    currency: "USD",
    alpha2: "JP",
    alpha3: "JPN",
    phone_prefix: 81,
    phone_sample: "90 1234 5678",
    id: 10123,
  },
];
